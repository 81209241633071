
import { Options, Vue } from 'vue-class-component'

@Options({
  props: ['value'],
  emits: ['deselect']
})
export default class SelectedItem extends Vue {
  public value!: string
}
