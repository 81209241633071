<template>
  <section class="list">
    <ListItem v-for="value in values"
              v-bind:key="value"
              v-bind:selected="isSelected(value)"
              v-bind:value="value"
              @toggle="$emit('change', $event)"
    ></ListItem>
  </section>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import ListItem from './ListItem.vue'

@Options({
  components: {
    ListItem
  },
  props: ['values', 'selected'],
  emits: ['change']
})
export default class List extends Vue {
  public values!: string[]
  public selected!: string[]

  public isSelected (value: string) {
    return this.selected.indexOf(value) !== -1
  }
}
</script>

<style scoped>
.list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 10px;
  column-gap: 5px;
}
</style>
