<template>
  <label class="list-item" v-bind:class="selected ? 'selected' : ''">
    <input type="checkbox" v-bind:checked="selected" @click="$emit('toggle', value)"/>
    <svg id="svg1" class="checkbox-control" viewBox="0 0 32 32" x="0" xmlns="http://www.w3.org/2000/svg" y="0">
      <rect height="30" rx="2" width="30" x="1" y="1"/>
      <path d="m6 6l20.125 20 m0.5-20l-20 20"/>
    </svg>
    <span>{{ value }}</span>
  </label>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'

@Options({
  props: ['value', 'selected'],
  emits: ['toggle']
})
export default class ListItem extends Vue {
  public value!: string
  public selected!: boolean
}
</script>

<style lang="less" scoped>
.list-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #ececec;
  border: 1px solid #c5c5c5;
  color: #20436e;
  padding: 5px;

  & .checkbox-control {
    width: 16px;
    height: 16px;

    & rect {
      stroke-linejoin: round;
      stroke: #acacac;
      stroke-width: 2;
      fill: none;
    }

    & path {
      stroke: #ea3323;
      stroke-linecap: round;
      stroke-width: 0;
    }
  }

  &:hover {
    background: #dcdcdc;
    cursor: pointer;
  }

  &.selected {
    background: white;

    & .checkbox-control path {
      stroke-width: 3px;
    }
  }
}

input[type="checkbox"] {
  display: none;
  width: 16px;
  height: 16px;
}

span {
  line-height: 15px;
  margin-left: 5px;
}
</style>
