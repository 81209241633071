<template>
  <section class="container">
    <Selected v-bind:values="selected"
              @change="handleChange"
    ></Selected>

    <List v-bind:selected="selected"
          v-bind:values="currencyList"
          @change="handleChange"
    ></List>
  </section>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import List from './components/List.vue'
import Selected from './components/Selected.vue'

@Options({
  components: {
    List,
    Selected
  }
})
export default class App extends Vue {
  public currencyList: string[] = ['EUR', 'PLN', 'GEL', 'DKK', 'CZK', 'GBP', 'SEK', 'USD', 'RUB']
  public selected: string[] = []

  public handleChange (value: string): void {
    const index: number = this.selected.indexOf(value)

    if (index === -1) {
      this.selected.push(value)
    } else {
      this.selected.splice(index, 1)
    }
  }
}
</script>

<style>
html, body, #app {
  height: 100%;
  font-family: Arial;
}

#app {
  color: #070707;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container {
  border: 1px solid #a3a3a3;
  border-radius: 4px;
  box-shadow: 2px 2px 2px #d9d9d9;
  padding: 15px;
  display: flex;
  flex-direction: column;
  width: 350px;
}

.list {
  min-height: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.list + .list {
  margin-top: 30px;
}
</style>
