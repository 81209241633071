
import { Options, Vue } from 'vue-class-component'
import SelectedItem from './SelectedItem.vue'

@Options({
  components: {
    SelectedItem
  },
  props: ['values'],
  emits: ['change']
})
export default class Selected extends Vue {
  public values!: string[]
}
