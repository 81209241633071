
import { Options, Vue } from 'vue-class-component'
import List from './components/List.vue'
import Selected from './components/Selected.vue'

@Options({
  components: {
    List,
    Selected
  }
})
export default class App extends Vue {
  public currencyList: string[] = ['EUR', 'PLN', 'GEL', 'DKK', 'CZK', 'GBP', 'SEK', 'USD', 'RUB']
  public selected: string[] = []

  public handleChange (value: string): void {
    const index: number = this.selected.indexOf(value)

    if (index === -1) {
      this.selected.push(value)
    } else {
      this.selected.splice(index, 1)
    }
  }
}
