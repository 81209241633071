
import { Options, Vue } from 'vue-class-component'

@Options({
  props: ['value', 'selected'],
  emits: ['toggle']
})
export default class ListItem extends Vue {
  public value!: string
  public selected!: boolean
}
