<template>
  <div>
    <span>{{ value }}</span>
    <button @click="$emit('deselect', value)">X</button>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'

@Options({
  props: ['value'],
  emits: ['deselect']
})
export default class SelectedItem extends Vue {
  public value!: string
}
</script>

<style scoped>
div {
  background: #e5e5e5;
  position: relative;
  font-size: 14px;
  line-height: 30px;
  text-align: center;
}

button {
  position: absolute;
  top: -7px;
  right: -7px;
  display: block;
  padding: 0;
  margin: 0;
  font-size: 10px;
  width: 20px;
  height: 20px;
  border: 2px solid white;
  border-radius: 50%;
  background: black;
  color: white;
}

button:hover {
  cursor: pointer;
  background: white;
  color: black;
  border: 2px solid black;
}
</style>
