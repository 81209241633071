<template>
  <section class="list">
    <SelectedItem v-for="value in values"
                  v-bind:key="value"
                  v-bind:value="value"
                  @deselect="$emit('change', $event)"
    ></SelectedItem>
  </section>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import SelectedItem from './SelectedItem.vue'

@Options({
  components: {
    SelectedItem
  },
  props: ['values'],
  emits: ['change']
})
export default class Selected extends Vue {
  public values!: string[]
}
</script>

<style scoped>
.list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 15px;
}
</style>
