
import { Options, Vue } from 'vue-class-component'
import ListItem from './ListItem.vue'

@Options({
  components: {
    ListItem
  },
  props: ['values', 'selected'],
  emits: ['change']
})
export default class List extends Vue {
  public values!: string[]
  public selected!: string[]

  public isSelected (value: string) {
    return this.selected.indexOf(value) !== -1
  }
}
